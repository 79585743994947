import {
    whiteColor,
    primaryColor,
    secondaryColor,
    infoColor
} from "assets/jss/material-dashboard-react.js";


const drawerWidth = 240;
const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        //  justifyContent: 'space-between',
        ...theme.mixins.toolbar
    },
    muiToolbarDense: {
        minHeight: 50
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,

    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: whiteColor,
        color: secondaryColor[1],
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    bottomNavigation: {
        backgroundColor: 'transparent',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    buttonNavigationAction: {
        color: 'inherit'
    },
    buttonNavigationsSelected: {
        color: `${infoColor[0]} !important`
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 12,

    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        fontWeight: 'bold',
        position: 'absolute'

    },
    title1: {
        color: "white"
    },
    avatarsmall: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
    logoDiv: {
        position: "absolute",
        height: '50px',
        top: '0'
    },
    imageStyle: {
        height: "inherit"
    }


});

export default styles;
