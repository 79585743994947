import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

// import material-ui/core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import Avatar from '@material-ui/core/Avatar';
// import Tooltip from '@material-ui/core/Tooltip';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// import material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import MessageIcon from '@material-ui/icons/Message';
import Person from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';


//import style
import styles from "assets/jss/components/customcomponents/mobileHeaderStyle";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);



    return (
        <>
            <AppBar className={classNames(classes.topBar)} >
                <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                    <div className={classes.logoDiv}>
                        <img src={props.logo} className={classes.imageStyle} alt="..." />
                    </div>
                    <div></div>

                    <IconButton onClick={() => props.history.push('/home/notification')} label="Notification" className={classes.iconButton}>
                        <NotificationsIcon fontSize="large"/>
                    </IconButton>

                </Toolbar>
            </AppBar>
            <AppBar className={classNames(classes.bottomBar)} >
                <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        showLabels
                        classes={{ root: classes.bottomNavigation }}
                    >
                        <BottomNavigationAction label="Home" onClick={() => props.history.push('/home')} icon={<HomeIcon />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }} />
                        <BottomNavigationAction label="Progress" onClick={() => props.history.push('/home/dashboard')} icon={<BarChartIcon />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }} />
                        <BottomNavigationAction label="Connect" onClick={() => props.history.push('/home/connect')} icon={<MessageIcon />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }}/>
                        {/* <BottomNavigationAction onClick={()=> props.history.push('/home/notification')}label="Notification" icon={<NotificationsIcon />} className={classes.buttonNavigationAction} /> */}
                        <BottomNavigationAction label="Profile" onClick={() => props.history.push('/home/profile')} icon={<Person />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }}/>
                    </BottomNavigation>
                </Toolbar>
            </AppBar>
        </>


    );
}

AdminNavbarLinks.propTypes = {
    classes: PropTypes.object
};
export default withRouter(AdminNavbarLinks);