import React from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";

// core components
import WebNavbarLinks from "./WebNavbarLinks.js";
import MobileNavbarLinks from './MobileNavbarLinks'


export default function Header(props) {
  return (
    <React.Fragment>
      
      <Hidden smDown implementation="css">
        <WebNavbarLinks {...props} />
      </Hidden>

      <Hidden mdUp implementation="css">
        <MobileNavbarLinks color="gray" {...props} />
      </Hidden>
    </React.Fragment>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
