import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";


//----- Fetch Content List------------------
export const fetchContentListStart = () => {
    return {
        type: actionTypes.FETCH_CONTENTLIST_START
    }
}

export const setContentList = (contentList, UserProgress, QuestId) => {
    return {
        type: actionTypes.SET_CONTENTLIST,
        contentlist: contentList,
        userprogress: UserProgress,
        questid: QuestId
    }
}

export const fetchContentListFailed = () => {
    return {
        type: actionTypes.FETCH_CONTENTLIST_FAILED
    }
}

export const initContentList = (QuestId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        dispatch(fetchContentListStart());
        await axiosConfig().get('/QuestContent/GetContentList', {
            params: {
                QuestId: QuestId,
            }
        }).then(response => {
            dispatch(initUserProgress(response.data, QuestId, UserId))
        }).catch(function (error) {
            dispatch(fetchContentListFailed())
        });
    }
}

export const initUserProgress = (ContentList, QuestId, UserId) => {
    return dispatch => {
        //dispatch(fetchContentListStart());
        axiosConfig().get('/QuestUser/GetUserProgress', {
            params: {
                QuestId: QuestId,
                UserId: UserId
            }
        }).then(response => {
            dispatch(setContentList(ContentList, response.data, QuestId))
        }).catch(function (error) {
            console.log(error);
            dispatch(fetchContentListFailed())
        });
    }
}

//------------set current mission------------
export const setCurrentMission = (current_mission) => {
    return {
        type: actionTypes.SET_CURRENT_MISSION,
        mission: current_mission
    }
}


//------------set current operation--------------
export const setCurrentOperation = (current_operation) => {
    return {
        type: actionTypes.SET_CURRENT_OPERATION,
        operation: current_operation
    }
}

//-----set current content-----------------
export const setCurrentContent = (current_content) => {
    return {
        type: actionTypes.SET_CURRENT_CONTENT,
        content: current_content
    }
}

//-----clear current content-----------------
export const clearCurrentContent = () => {
    return {
        type: actionTypes.CLEAR_CURRENT_CONTENT,
    }
}

//-----Update current content (marksObtained,pointCardSatus)-----------------
export const updateCurrentContent = (pointCardSatus) => {
    return {
        type: actionTypes.UPDATE_CURRENT_CONTENT,
        pointCardSatus: pointCardSatus,
    }
}

//-----set current Test-----------------
export const setCurrentTest = (testCode) => {
    return {
        type: actionTypes.SET_CURRENT_TESTS,
        testCode: testCode
    }
}


//-------- update Answer duration------------------
export const updateTestAttemptedDuration = (duration) => {
    return {
        type: actionTypes.UPDATE_TEST_ATTEMPT_DURATION,
        duration: duration,
    }
}

//-------- update TestAttempt Count------------------
// For check Current Test is attempted or not 
export const updateTestAttemptCount = () => {
    return {
        type: actionTypes.UPDATE_TEST_ATTEMPT_COUNT,
    }
}



//----- Post Content Rating ------------------
export const postContentRatingtStart = () => {
    return {
        type: actionTypes.POST_CONTENT_RATING_START
    }
};

export const postContentRatingSuccess = () => {
    return {
        type: actionTypes.POST_CONTENT_RATING_SUCCESS
    }
};

export const postContentRatingFailed = () => {
    return {
        type: actionTypes.POST_CONTENT_RATING_FAILED
    }
};

export const postContentRating = (formData) => {
    return dispatch => {
        dispatch(postContentRatingtStart());
        axiosConfig().post('/QuestUser/SetContentRating', formData)
            .then(response => {
                dispatch(postContentRatingSuccess())
                dispatch(initContentRating(formData.contentId)) // fetch Content Rating
            }).catch((error) => {
                dispatch(postContentRatingFailed())
            });
    }
}

//----- Fetch Content Rating------------------
export const fetchContentRatingStart = () => {
    return {
        type: actionTypes.FETCH_CONTENT_RATING_START
    }
}

export const setContentRating = (contentRatings) => {
    return {
        type: actionTypes.SET_CONTENT_RATING,
        contentRatings: contentRatings,
    }
}

export const fetchContentRatingFailed = () => {
    return {
        type: actionTypes.FETCH_CONTENT_RATING_FAILED
    }
}

export const initContentRating = (ContentId) => {
    return async dispatch => {
        dispatch(fetchContentRatingStart());
        await axiosConfig().get('/QuestContent/GetContentRating', {
            params: {
                ContentId: ContentId,
            }
        }).then(response => {
            dispatch(setContentRating(response.data))
        }).catch(function (error) {
            dispatch(fetchContentRatingFailed())
        });
    }
}



//----- Post Content Access  ------------------
export const postContentAccesstStart = () => {
    return {
        type: actionTypes.POST_CONTENT_ACCESS_START
    }
};

export const postContentAccessSuccess = () => {
    return {
        type: actionTypes.POST_CONTENT_ACCESS_SUCCESS
    }
};

export const postContentAccessFailed = () => {
    return {
        type: actionTypes.POST_CONTENT_ACCESS_FAILED
    }
};


export const postContentAccess = (formData) => {
    return dispatch => {
        dispatch(postContentAccesstStart());
        axiosConfig().post('/QuestUser/SendContentAccess', formData)
            .then(response => {
                dispatch(postContentAccessSuccess());
                dispatch(updateCurrentContent(response.data))// update content,current_cuntent and quest_progress
            }).catch((error) => {
                dispatch(postContentAccessFailed())
            });
    }
}

//----- content Download------------------
export const contentDownload = (ContentId) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestUser/SetContentDownload', {
            params: {
                UserId: UserId,
                ContentId: ContentId
            }
        }).then(response => {
        }).catch(function (error) {
            console.log(error);
        });
    }
}
