import React from "react";
// @material-ui/core
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";

//Lottie
import Lottie from 'react-lottie';
import WellDoneAnimation from 'assets/lotties/trophy';
import TryAgainAnimation from 'assets/lotties/piggy-bank';

// styles
import useStyles from "assets/jss/components/views/failcardStyle";

const WellDoneOptions = {
  loop: true,
  autoplay: true,
  animationData: WellDoneAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const TryAgainOptions = {
  loop: true,
  autoplay: true,
  animationData: TryAgainAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}


export default function FailCard(props) {
  const classes = useStyles();

  const attemptAgain = () => {
    let Content = null;
    switch (props.contentTypeCode) {
      case ('01'):
        Content = '/player';
        break;
      case ('02'):
        Content = '/player';
        break;
      case ('03'):
        Content = '/player';
        break;
      case ('04'):
        Content = '/pdf';
        break;
      case ('05'):
        Content = '/pdf';
        break;
      case ('06'):
        Content = '/contentviewer';
        break;
      case ('07'):
        Content = '/viewer';
        break;
      case ('08'):
        Content = '/instruction';
        break;
      case ('09'):
        Content = '/instruction';
        break;
      case ('10'):
        Content = '/activity';
        break;
      default:
        Content = null;
    }
    props.onAttemptAgain(Content)
  }


  let LottieContent = null;
  let DialogMessage = ""
  if (Number(props.pointCardSatus.animationType) === 1) {
    LottieContent = <Lottie options={WellDoneOptions} height={'80%'} width={"80%"} />
    DialogMessage = "Congrats! You have achieved this task."

  } else if (Number(props.pointCardSatus.animationType) === 2) {
    LottieContent = <Lottie options={TryAgainOptions} height={200} width={200} />
    DialogMessage = "Oops! You missed to achieve this task."
  }




  return (

    <React.Fragment>
      <DialogTitle className={classes.dialogTitle}>
        {LottieContent}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography>
          {DialogMessage}
        </Typography>

        {props.pointCardSatus.showPoint &&
          <React.Fragment>
            <Typography style={{ fontWeight: "bold" }}>
              You have collected
             </Typography>

            <Typography style={{ fontWeight: "bold" }}>
              {props.pointCardSatus.points}
            </Typography>

            <Typography>
              points for this task.
            </Typography>
          </React.Fragment>

        }



      </DialogContent>

      <DialogActions className={classes.dialogActions}>

        <Button color="primary" size="sm" outlined onClick={props.onClose}>
          Not Now
         </Button>

        <Button color="primary" size="sm" onClick={attemptAgain}>
          Attempt again
         </Button>

      </DialogActions>
    </React.Fragment>

  );
}
