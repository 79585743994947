import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    leader_board_data: [],
    leader_board_data_error: false,
    leader_board_data_loading: true,

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        // ------- Leader Board Data -------------
        case actionTypes.FETCH_LEADERBOARD_DATA_START:
            return {
                ...state,
                leader_board_data_loading: true
            }
        case actionTypes.SET_LEADERBOARD_DATA:
            return {
                ...state,
                leader_board_data: action.leaderboard.leaderBoard,
                leader_board_data_error: false,
                leader_board_data_loading: false
            }
        case actionTypes.FETCH_LEADERBOARD_DATA_FAILED:
            return {
                ...state,
                leader_board_data_error: true,
                leader_board_data_loading: false
            }


        default:
            return state;
    }

}


export default reducer