import {
  whiteColor,
  blackColor,
} from "assets/jss/material-dashboard-react.js";

const buttonStyle = {
  white: {
    backgroundColor: whiteColor,
    color: blackColor //grayColor[0]
  },
  info: {
    backgroundColor: 'rgb(232, 244, 253)',
  },
  success: {
    backgroundColor: 'rgb(237, 247, 237)',
  },
  warning: {
    backgroundColor: 'rgb(255, 244, 229)',
  },
  danger: {
    backgroundColor: 'rgb(253, 236, 234)',
  },
  error: {
    backgroundColor: 'rgb(253, 236, 234)',
  },
  like: {
    backgroundColor: 'transparent',
  },

};

export default buttonStyle;
