import React, { useState } from "react";

//Redux
import { useDispatch, useSelector } from 'react-redux'

//Custom Components
import MessagesCard from "components/Views/Connect/components/MessageComponents/MessagesCard";
//Style
import useStyles from './styles.js'

const Inbox = (props) => {
    const { loading } = props
    const todyasOnlineClasses = useSelector(state => state.onlineclasses.todyasOnlineClasses.scheduleDataResult)
    const classes = useStyles();
       return (
        <div className={classes.mainContainer}>
            <MessagesCard
                data={todyasOnlineClasses}
                onClickRow={() => { }}
                loading={loading}
                noDataMessage={"No data available"}
                type={"1"}
            />
        </div>
    )
}

export default Inbox;