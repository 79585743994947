import * as actionTypes from './actionTypes';
import { serverURL } from 'variables/ServerURL'

export const fetchBoardGrade = () => {
    return async dispatch => {
        try {
            const response = await fetch(serverURL+"QuestContent/GetBoardGrade ")
            if (!response.ok)
                throw new Error('Something went wrong!');
            else {
                const resData = await response.json();
                dispatch({
                    type: actionTypes.SET_BOARD_GRADE,
                    payload: resData
                });
            }
        } catch (error) {
            throw error;
        }
    }
}