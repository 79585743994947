import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';

// import material-ui/core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


// import material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";

//import style
import styles from "assets/jss/components/customcomponents/pageNavigationStyle.js";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = (props) => {
    const classes = useStyles();
    const [alertDialog, setAlertDialog] = React.useState(false);
    // const [value, setValue] = React.useState(0);

    /*-----------------------handle Alert Close Dialog--------------------*/
    const handleAlertCloseDialog = () => {
        setAlertDialog(false);
    };

    /*-----------------------handle back--------------------*/
    const handleBack = () => {
        if (props.alert) {
            setAlertDialog(true);
        } else {
            props.back ? props.history.push(props.back) : props.history.goBack()
        }

    };

    /*-----------------------handle Alert onOK--------------------*/
    const handleAlertOK = async () => {
        handleAlertCloseDialog()
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.root}>

                <AppBar className={classNames(classes.appBar)} >
                    <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                        <div style={{ flexDirection: "row", display: "flex", alignItems: 'center', color: "black" }}>
                            <IconButton aria-label="ArrowBackIcon" onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>

                            <Typography variant="body1" className={classes.title}>
                                {props.logoText}
                            </Typography>
                        </div>

                    </Toolbar>
                </AppBar>
            </div>

            {/*-----Alert Dialog----- */}
            <AlertDialog
                open={alertDialog}
                type="info"
                title={props.alertMessage}
                ContentText={null}
                cancelable={() => { }}
                action={[
                    { text: 'OK', onPress: handleAlertOK }
                ]}
            />

        </React.Fragment>

    );
}
AdminNavbarLinks.defaultProps={
    alert: false,
}
AdminNavbarLinks.propTypes = {
    classes: PropTypes.object,
    alert: PropTypes.bool,
    alertOK: PropTypes.func,
    alertMessage: PropTypes.string
};
export default withRouter(AdminNavbarLinks);