import React, { useState } from 'react';
//@material-ui/core
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';
//Views
import TopicCards from 'components/Views/Tasks/Topics/TopicCards'



const AutoPlaySwipeableViews = (props) => {
    const { topics,activeStep, ...rest } = props
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    return (
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            containerStyle={{ width: sm ? '100%' : '15%', minWidth: 200 }}
            style={{ padding: props.activeStep === 0 ? md ? '0px 75% 0px 0px' : '0px 45% 0px 0px' : md ? '0px 65.5% 0 10%' : '0px 22.5%' }}
            index={props.activeStep}
            enableMouseEvents
            {...rest}
        >
            {topics.map((step, index) => (
                <div key={step.contentId + index}>
                    <TopicCards {...step} />
                </div>
            ))}
        </SwipeableViews>
    )
};

export default AutoPlaySwipeableViews;