import React from "react";
import { connect } from 'react-redux'

// @material-ui/core
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
import WebTopics from 'components/Views/Tasks/Topics/WebTopics'
import OperationCards from 'components/Views/Tasks/Operation/Operation'


// styles
import useStyles from "assets/jss/components/views/taskStyle";
import './style.css'


const Operations = (props) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.mission.missionDisplayName} back={'/home'} />

            {/*-----page-container----- */}
            <div className="task-container">

                <Hidden smDown implementation="css">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4} className="operation-container">
                            <div style={{ height: '90vh', overflow: "overlay", padding: 30, marginRight: '-30px' }} className='scroll'>
                                <OperationCards onClick={() => { }} ></OperationCards>
                            </div>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={8} className="topics-container">
                            <Typography variant="body1" className={classes.topicTitle}>
                                {props.operation.operationDisplayName}
                            </Typography>
                            <div style={{ height: '87vh', overflow: "overlay", padding: 15, marginRight: '-15px' }} className='scroll'>
                                <WebTopics/>
                           </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>

                <Hidden mdUp implementation="css" className={classes.mdUpHiddenStyle}>
                    <GridContainer >
                        <GridItem xs={12} sm={12} md={4} className="operation-container">
                            <div style={{ height: '90vh', overflow: "overlay", paddingLeft: 30, paddingRight: 30 }}>
                                <OperationCards onClick={() => props.history.push({ pathname: '/topics' })} ></OperationCards>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>
            </div>
        </React.Fragment>
    )
}


const mapSateToProps = state => {
    return {
        mission: state.content.current_mission,
        operation: state.content.current_operation,
    }
}


export default connect(mapSateToProps)(Operations);
