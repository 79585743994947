import React, { useState,useEffect } from 'react';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// core components
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'

//import style
import 'assets/css/loader.css';

//variables
import { CURRENT_DATE_TIME } from "variables/general.js";

const DocView = (props) => {
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState('none')

    const hideSpinner = () => {
        setLoading(false);
        setDisplay('block')
    };

    useEffect(() => {
        let accessTime=0;
        const timer = setInterval(() => {
            accessTime++
        }, 1000);
        return () =>{
            if(accessTime>10)
            {
                const BODY_DATA = {
                    "missionActivityId": "", // Don't set this
                    "userId": props.userInfo.userId,
                    "locationId": props.userInfo.locationId,
                    "groupId": props.userInfo.groupId,
                    "questId": props.quest.questId,
                    "contentId": props.content.contentId,
                    "contentTypeCode": props.content.contentTypeCode,
                    "accessDate": "" + CURRENT_DATE_TIME(),
                    "accessDuration": "" + accessTime,
                    "missionActivitySummaryId": "" // Don't set this
                }
                props.onPostContentAccess(BODY_DATA)
            }
        
            clearTimeout(timer)};
      }, []);


    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.content.contentDisplayName} />

            {/*-----page-container----- */}
            <div className='page-container-5015'>
                  {loading ? (
                    <div style={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div className="loader">Loading...</div>
                    </div>

                ) : null}
                <iframe
                    title="HTMLViewer"
                    style={{
                        position: 'relative',
                        left: 0,
                        // bottom: 0,
                        right: 0,
                        width: '100%',
                        minHeight: '100vh',
                        border: "none",
                        display: display
                    }}
                    onLoad={hideSpinner}
                    src={props.content.fileUrl}
                />

            </div>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPostContentAccess: (accessData) => dispatch(questActions.postContentAccess(accessData)), //also update update content,current_cuntent and quest_progress (updateCurrentContent)
        //updateCurrentContent: (marksObtained, cardSatus, accessCount) => dispatch(questActions.updateCurrentContent(marksObtained, cardSatus, accessCount)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocView);

