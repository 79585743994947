
import React from 'react';

//@material-ui/core
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Button from "components/CustomComponents/CustomButtons/Button.js";
import SwipeableView from "components/CustomComponents/SwipeableView/SwipeableView";

import SwipeCard from "components/Views/QuestProducts/SwipeableViews/SwipeCard";

// custom style
import useStyles from "assets/jss/components/views/questCategoryStyle";
import DefaultImage from "assets/img/task/default.png";

const AutoPlaySwipeableViews = (props) => {
    const classes = useStyles();

    return (
        <SwipeableView index={props.activeStep} onChangeIndex={props.stepChange} >
            {props.topics.map((step, index) => (
                <div key={step.questId + "_" + index} style={{ width: '100%' }}>
                    <SwipeCard
                        name={step.questName}
                        image={step.questIcon}
                        data={step}
                        {...props}
                    />
                    {/* <GridContainer className={classes.webTopicCard}>
                        <GridItem xs={12} sm={12} md={12} className={classes.topicCardGrid} >
                            <Card className={classes.contentCardStyle}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    component="img"
                                    alt={step.questName}
                                    height="140"
                                    image={step.questIcon ? step.questIcon : DefaultImage}
                                    title={step.questName}
                                />
                                <CardContent >
                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <Typography variant="subtitle2" component="h2" className={classes.contentCardTitle}>
                                            {step.questName}
                                        </Typography>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                                            <samp className={classes.hyperText} onClick={() => props.onSeeMore(step)}>Details</samp>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', visibility: (!(step.isFree || step.isAssigned)) ? 'visible' : "hidden" }}>
                                                <Typography variant="caption" style={{ paddingRight: 2 }}>
                                                    ₹
                                                    </Typography>
                                                <Typography variant="caption" style={{ color: '#000', fontWeight: "bold", paddingRight: 10 }} >
                                                    {Math.floor(step.price) + ''}
                                                </Typography>
                                            </div>
                                        </div>

                                        {step.isFree
                                            ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                <div></div>
                                                {
                                                    step.isOnline === true || step.isOnline === undefined || step.isOnline === null
                                                        ? <Button fullWidth style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(step)}  >Launch</Button>
                                                        : <Button  color="info" size="xs" onClick={() => props.onOfflineLaunch(step.formUrl)} >I am interested</Button>
                                                }

                                            </div>
                                            : step.isTrialTaken
                                                ? step.isAssigned
                                                    ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(step)}  >Launch</Button>
                                                    </div>
                                                    : <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <div></div>
                                                        <Button fullWidth style={{ width: '40%' }} label='Buy' color="rose" size="xs" onClick={() => props.onBuy(step)}  >Buy</Button>
                                                    </div>
                                                : step.isAssigned
                                                    ? <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button style={{ width: '40%' }} color="primary" size="xs" onClick={() => props.onLaunch(step)}  >Launch</Button>
                                                    </div>
                                                    : step.isOnline === true || step.isOnline === undefined || step.isOnline === null
                                                        ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: -15 }}>
                                                            <Button fullWidth style={{ marginRight: 3, marginLeft: -1 }} color="warning" size="xs" onClick={() => props.onSeeMore(step)}  >Try</Button>
                                                            <Button fullWidth style={{ marginLeft: 3 }} color="rose" size="xs" onClick={() => props.onBuy(step)}  >Buy</Button>
                                                        </div>
                                                        : <div style={{ marginBottom: -15, display: 'flex', justifyContent: 'flex-end' }}>
                                                            <Button  color="info" size="xs" onClick={() => props.onOfflineLaunch(step.formUrl)} >I am interested</Button>
                                                        </div>
                                        }
                                    </div>
                                </CardContent>
                            </Card>

                            <Typography variant="caption" component="h2" className={classes.freeText} style={{ backgroundColor: step.tagColor }}>
                                {step.tagValue}
                            </Typography>
                        </GridItem>
                    </GridContainer> */}
                </div >
            ))
            }
        </SwipeableView >
    )
};

export default AutoPlaySwipeableViews;