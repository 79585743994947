import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//import reducer 
import { useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'

// @material-ui/core
import Backdrop from '@material-ui/core/Backdrop';

//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/star-success';

//View
import Dialog from "components/CustomComponents/Dialog/Dialog";
import PointCard from 'components/Views/PointCards/PointCard'

// styles
import useStyles from "assets/jss/components/views/taskStyle";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const PointCardContainer = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const content = useSelector(state => state.content.current_content);
    const [flashAnimation, setFlashAnimation] = useState(false)
    const [pointsDialog, setPointsdialog] = useState(false);

    useEffect(() => {
        let myInterval = null
        if (Object.keys(content).length) {
            if (content.pointCardSatus) {
                if (Number(content.pointCardSatus.animationType) === 1) {
                    setFlashAnimation(true)
                    myInterval = setTimeout(() => {
                        setFlashAnimation(false);
                        setPointsdialog(true)
                    }, 2000);
                } else if (Number(content.pointCardSatus.animationType) === 2) {
                    myInterval = setTimeout(() => {
                        setPointsdialog(true)
                    }, 1000);
                }
            }
        }
        return () => {
            clearInterval(myInterval);
        };
    }, [content.pointCardSatus])

    /*-----------------------handle  Close Dialog--------------------*/
    const handleCloseDialog = () => {
        dispatch(questActions.clearCurrentContent())
        setPointsdialog(false);
    };

    /*-----------------------handle Attempt Again--------------------*/
    const handleAttemptAgain = async (link) => {
        setPointsdialog(false);
        await dispatch(questActions.setCurrentContent(content)) //this set pointCardSatus={}
        history.push(link)
    };

  
    return (
        <React.Fragment>
            {flashAnimation && <Backdrop open={flashAnimation} className={classes.backdrop}>
                <Lottie options={defaultOptions}
                    height={'100%'}
                    width={'100%'} />
            </Backdrop>
            }

            {/*-----Dialog----- */}
            <Dialog open={pointsDialog} maxWidth={"xs"}>
                {content.pointCardSatus ?
                    <PointCard {...content} onClose={handleCloseDialog} onAttemptAgain={handleAttemptAgain} />
                    : null
                }
            </Dialog>
        </React.Fragment>
    )
}

export default PointCardContainer;