import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// style
import {  
  infoColor
} from "assets/jss/material-dashboard-react.js";


const StyledProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#9bf1eb'
  },
  bar: {
    backgroundColor: infoColor[0],
  },

})(LinearProgress);

const LinearProgressWithLabel=(props)=> {
 const {textcolor,value,...rest}=props;

  return (
    <Box display="flex" alignItems="center" width='100%'>
      <Box width="95%" mr={1}>
        <StyledProgress  variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="caption" style={{color:textcolor,fontWeight:600}}>{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.defaultProps = {
  textcolor: infoColor[0]
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  textcolor:PropTypes.string
};

export default LinearProgressWithLabel;



