import React, { useState, useEffect } from 'react';
//Redux
import { connect, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
//Custom Components
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
// import TopicCards from 'components/Views/Tasks/Topics/TopicCards'
import AutoPlaySwipeableViews from 'components/Views/Tasks/Topics/TaskSwipeableViews'
import PointCardContainer from 'components/Views/Tasks/Topics/PointCardContainer'
// // style
import useStyles from 'assets/jss/components/views/topicsContainerStyle'

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const AutoPlaySwipeableViews = (props) => {
//     const [activeStep, setActiveStep] = React.useState(0);
//     const handleStepChange = (step) => {
//         setActiveStep(step);
//       };

//     const theme = useTheme();
//     return (
//         <SwipeableViews
//             axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//             index={activeStep}
//             // containerStyle={{ width: '100%' }}
//             style={{padding: activeStep===0?'0px 120px 0px 0px':'0px 60px'}}
//             onChangeIndex={handleStepChange}
//             enableMouseEvents
//             ignoreNativeScroll={true}
//         >
//             {props.topics.map((step, index) => (
//                 <div key={step.contentId + index} style={{width:'100%'}}>
//                     <TopicCards {...step} />
//                 </div>
//             ))}
//         </SwipeableViews>
//     )
// };



const TaskCard = (props) => {
    const classes = useStyles();
    const { task, topics } = props

    const currentContent = useSelector(state => state.content.current_content)
    const currentStep = topics.findIndex(element => element.contentId === currentContent.contentId);
    const [activeStep, setActiveStep] = useState(currentStep>=0 ? currentStep : 0);

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
   return (
        <div className={classes.swipeableViewsContainer} >
            <div className={classes.buttonDiv}>
                <Typography variant="body1" className={classes.topicsTitle}>
                    {task.taskDisplayName}
                </Typography>
            </div>
            <AutoPlaySwipeableViews
                activeStep={activeStep}
                topics={topics}
                onChangeIndex={handleStepChange}
                ignoreNativeScroll={true}
            />
        </div >

    )
}





function SwipeableTextMobileStepper(props) {

    const classes = useStyles();
    const theme = useTheme();
    /*const fullScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (fullScreen)
            props.history.push('/task')
    }, [fullScreen])
    */

    const questContentsList = props.content.add.questContents.filter((questContent) => (props.operation.operationId === questContent.operationId) && questContent["taskId"] === undefined)
    const taskList = props.content.add.tasks.filter((task) => props.operation.operationId === task.operationId)


    return (
        <div className={classes.root}>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.operation.operationDisplayName}  back={'/task'}/>
            
            <PointCardContainer />

            {/*-----page-container----- */}
            <div className="page-container-5015 background-tasks">
                {questContentsList.length ? <TaskCard task={""} topics={questContentsList} /> : null}
                {taskList.length
                    ?
                    taskList.map(current_task =>
                        <TaskCard
                            key={current_task.taskId}
                            task={current_task}
                            topics={props.content.add.questContents.filter((questContent) => current_task.taskId === questContent.taskId)} />
                    ) : null
                }
            </div>
        </div >
    );
}

const mapStateToProps = state => {
    return {
        content: state.content.contentlist,
        operation: state.content.current_operation
    }
}

export default connect(mapStateToProps, null)(SwipeableTextMobileStepper);
