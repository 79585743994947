import React, { useEffect, useState } from "react";
//Redux
import { connect, useDispatch } from 'react-redux'
//axios
import axios from 'axios';
import axiosConfig from "util/axios";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import CardActionArea from '@material-ui/core/CardActionArea';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
// core components
import Avatar from '@material-ui/core/Avatar';
import Dialog from "components/CustomComponents/Dialog/Dialog";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Card from "components/CustomComponents/Card/Card.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";
import CardIcon from "components/CustomComponents/Card/CardIcon.js";
import CardFooter from "components/CustomComponents/Card/CardFooter.js";
import Progress from 'components/CustomComponents/Progress/Progress'
import LinearProgressWithLabel from 'components/CustomComponents/Progress/LinearProgressWithLabel'
import Info from 'components/CustomComponents/Typography/Info';
import Button from "components/CustomComponents/CustomButtons/Button.js";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
// Views
import Instruction from 'components/Views/Instruction/Instruction'
import Quest from 'components/Views/Quest/Quest'
import LeaderBoard from 'components/Views/LeaderBoard/LeaderBoard'
import CustomizedDialog from "components/CustomComponents/Dialog/CustomizedDialog";
// img
import avatar from "assets/img/trophy.png";
import QuestLogo from "assets/img/icons/Questlogoheader_white.png";

//import reducer 
import * as questActions from 'store/actions/index'
//commonfunctions
import { clevertapEvents } from "util/commonfunctions";
//variables
import { NexoolURL, LastUrl } from "variables/ServerURL";
// styles
import styles from "assets/jss/components/views/homeStyle";
import DefaultImage from "assets/img/task/default.png";
import ProductImage from "assets/img/task/Product.jpg";
const useStyles = makeStyles(styles);




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SkeletonCard = (props) => {
  const classes = useStyles();
  return (<React.Fragment>
    {['001', '010', '011', '111'].map((v, i) =>
      <GridItem xs={6} sm={6} md={3} key={v + 'skeleton#' + i} className={classes.gridItemMissions}>
        <Card className={classes.cardMissions}  >
          <CardActionArea>
            <CardBody plain>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <CardIcon >
                    <Skeleton animation="wave" variant="rect" width={70} height={70} />
                  </CardIcon>
                </GridItem>
                <GridItem xs={12} sm={8} md={8} >
                  <CardFooter className={classes.stats} >
                    <Skeleton animation="wave" width="50%" />
                    <div className={classes.infoText}>
                      <Skeleton animation="wave" width="90%" />
                    </div>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardActionArea>
        </Card>
      </GridItem>
    )}
  </React.Fragment>
  )
}


const Home = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(false);
  const [questDialog, setQuestDialog] = React.useState(false);
  const [leaderboardDialog, setLeaderboardDialog] = React.useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [newUserAlert, setNewUserAlert] = useState(false)

  // useEffect(() => {
  //   // props.userInfo.userId is not present means session is invalid
  //   if (props.userInfoError || props.userAuthError) {
  //     console.log("session is invalid");
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     props.history.push('/');
  //   }
  // }, [props.userInfoError,props.userAuthError])

  /*----------------------- Zoom -------------------*/
  useEffect(() => {
    if (typeof window.Android !== 'undefined' && typeof window.Android.setWebViewZoom !== 'undefined') {
      console.log("Calling Zoom");
      window.Android.setWebViewZoom(false);
    }
  }, [])

  /*-----------------------handle Close Dialog--------------------*/
  const handleCloseDialog = () => {
    setDialogState(false);
  };

  /*-----------------------handle Close Dialog--------------------*/
  const handleOpenDialog = (mission) => {
    props.setCurrentMission(mission) //set selected mission
    setDialogState(true);
  };

  /*-----------------------handle Close Dialog--------------------*/
  const onStart = () => {
    //return this.props.history.push('/main')
    setDialogState(false);
    props.history.push('/task')

  }

  /*-----------------------handle Quest Dialog Open --------------------*/
  const openQuestDialog = (apptype) => {
    if (apptype === 'Spectrum')
      setQuestDialog(true);
    else
      props.history.push('/products')
  };
  /*-----------------------handle Quest Dialog Close --------------------*/
  const closeQuestDialog = () => {
    setQuestDialog(false);
  };
  /*----------------------- Change Quest Data --------------------*/
  const onChangeQuest = (CurrentQuest) => {
    setQuestDialog(false);
    props.setCurrentQuest(CurrentQuest)
    props.onInittContentList(CurrentQuest.questId)
  }


  /*-----------------------handle Leader Board Close --------------------*/
  const handleCloseLeaderBoardDialog = () => {
    setLeaderboardDialog(false);
  };

  /*-----------------------handle Leader Board Open --------------------*/
  const handleOpenLeaderBoardDialog = () => {
    setLeaderboardDialog(true);
    props.onInitLeaderBoard(props.quest.questId)
  };


  //-----------------------Handel Buy Quest---------------------
  const onBuyQuest = async (CurrentQuest) => {
    const cartItem = {
      "userId": props.userInfo.userId,
      "productId": CurrentQuest.productId,
    }
    //clevertap
    const eventData = JSON.stringify({
      'UserId': props.userInfo.userId,
      'ProductId': CurrentQuest.productId,
      'ProductName': CurrentQuest.questName
    });

    clevertapEvents('PurchaseInitiate', eventData);

    await dispatch(questActions.addToCart(cartItem));
    props.history.push('/cart');
  }

  /*----------------------- Open URL --------------------*/
  const onOpenURL = async () => {
    //setLoadingSpinner(true)
    await axiosConfig().get('/QuestUser/GetNexoolToken', {
      params: {
        UserId: props.userInfo.userId,
        QuestId: props.quest.questId
      }
    }).then(response => {
      if (typeof window.Android !== 'undefined') {
        if (typeof window.Android.setWebViewZoom !== 'undefined') {
          console.log("Calling Zoom");
          window.Android.setWebViewZoom(true);
        }
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      } else if (typeof openNewUrl !== 'undefined') {
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      } else {
        var w = window.open(`${NexoolURL}?token=${response.data.token}&lastUrl=${LastUrl}`, "_self")
      }
    })
  }

  /*----------------------- Push the App Registrations lead data on CRM tool --------------------*/
  useEffect(() => {
    console.log('pushDataToCRM', props.usertype);
    if (props.questLoading === false && props.infoLoading === false) {
      if (props.usertype === 'NewUser') {
        setNewUserAlert(true)
        pushDataToCRM();
        dispatch(questActions.setUserType('ExistingUser'))
      }
    }
  }, [props.questLoading, props.infoLoading])

  const pushDataToCRM = () => {
    const params = {
      phone: props.userInfo.userName,
      email: '',
      name: props.userInfo.firstName,
      agent_centre: 'op',
      campaign: 'App Registration',
      source: 'Live Lead',
      product_name: props.quest.questName,
      extra: ""
    };

    //axios.get('https://lms.obliquepyramid.com/user_tracking/insert_leads/no_validation.php', { params })
    axiosConfig().get('/QuestUser/SetCRMData', {
        params: {
          phone: props.userInfo.userName,
          name: props.userInfo.firstName,
          product_name: props.quest.questName
        }
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (
    <div>
      {/*----- Spinner---- */}
      <Progress color="secondary" open={loadingSpinner} />

      {/*-----Dialog----- */}
      <Dialog open={dialogState} maxWidth={"xs"} TransitionComponent={Transition} >
        <Instruction onClose={handleCloseDialog} onStart={onStart} />
      </Dialog>

      {/*-----Dialog Quest----- */}
      <Dialog open={questDialog} maxWidth={"xs"} scroll="paper" TransitionComponent={Transition} >
        <Quest
          logo={QuestLogo}
          data={props.userquest}
          activeData={props.quest}
          onChange={onChangeQuest}
          onClose={closeQuestDialog}
        />
      </Dialog>

      {/*-----Dialog Leaderboard ----- */}
      <CustomizedDialog
        title={"Leader Board"}
        open={leaderboardDialog}
        maxWidth={"xs"}
        fullWidth={true}
        scroll="paper"
        TransitionComponent={Transition}
        onClose={handleCloseLeaderBoardDialog}
      >
        <LeaderBoard
          data={props.leaderboard}
          loading={props.leaderboardLoading}
          onClose={handleCloseLeaderBoardDialog}
        />
      </CustomizedDialog>


      {/*----- Spinner---- */}
      {/* {props.loading || props.infoLoading || props.questLoading ? <Progress color="secondary" open={props.loading || props.infoLoading || props.questLoading} /> : null} */}

      <div className='page-container background-home'>

        <GridContainer>

          {props.userquest.questList.length === 0
            ? <div className={classes.warningAlert}>
              <Alert severity="warning" elevation={20}>
                <AlertTitle>Quest assign</AlertTitle>
                Your next year's quest will be assigned soon.
              </Alert>
            </div>
            : null
          }

          <AlertDialog
            open={newUserAlert}
            type="info"
            ContentTextStyle={{ color: 'black', marginBottom: 0 }}
            title={'Welcome to Singhania Quest+ Learning App.'}
            titleStyle={{ paddingBottom: 0 }}
            ContentText={'Our Quest+ Education Counsellor will be calling you soon to guide you about the App. Please continue exploring.'}
            cancelable={() => { }}
            action={[
              { text: 'OK', onPress: ()=>setNewUserAlert(false) }
            ]}
          />




          <GridItem xs={12} sm={9} md={9} className={classes.gridItemHeader}>
            <div style={{ color: "#fff", padding: 20 }}>

              <div style={{ flexDirection: "column", display: 'flex', marginBottom: 0 }}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", lineHeight: 1.2 }}>
                  {props.infoLoading ? <Skeleton animation="wave" width="50%" /> : "Hello " + props.userInfo.firstName + ","}
                </Typography>
                {/* <Typography variant="caption" >
                  {props.infoLoading ? <Skeleton animation="wave" width="50%" /> : "Let's explore this quest"}
                </Typography> */}
              </div>

              {(props.questLoading || props.infoLoading)
                ? <React.Fragment>
                  <Skeleton animation="wave" height={20} width="30%" />
                  <Skeleton animation="wave" height={30} width="100%" />
                </React.Fragment>
                : <React.Fragment>
                  <div className={classes.questDiv}>
                    <div style={{ flexDirection: "row", display: "flex", alignItems: 'center' }}>
                      <Avatar variant="square" classes={{ img: classes.square }} src={QuestLogo} />
                      <Typography variant="subtitle2" component="h6" style={{ fontWeight: "bold", marginRight: 25 }}>
                        {props.quest.questName}
                      </Typography>
                    </div>

                    {props.userquest.questList.length > 0 && <Button color="white" size="xs" onClick={() => openQuestDialog(props.apptype)}>
                      Change
                    </Button>}
                  </div>
                  <LinearProgressWithLabel value={props.questProgress.progress ? props.questProgress.progress : 0} textcolor={'#ffffff'} />

                  {props.apptype === 'Spectrum'
                    ? null
                    : <div className={classes.buyButtonContainer} style={{ visibility: props.quest.isOnTrial ? "visible" : "hidden" }}>
                      <Typography variant="caption" style={{ fontWeight: "bold", padding: 5 }}>
                        {props.quest.tagValue}
                      </Typography>

                      <Button
                        round
                        onClick={() => onBuyQuest(props.quest)}
                        color="rose"
                        size="xs" >
                        Buy Now
                      </Button>
                    </div>
                  }

                </React.Fragment>
              }
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Paper className={classes.paperMissions}>

              <CardActionArea className={classes.cardAvatar} onClick={handleOpenLeaderBoardDialog}>
                <img className={classes.imageStyle} src={avatar} alt="..." />
              </CardActionArea>

              <CardBody className={classes.pageCardBody}>
                <GridContainer>
                  {props.loading
                    ? <SkeletonCard />

                    : <React.Fragment>
                      {props.questContent.add.missions.map((mission, index) => (
                        <GridItem xs={6} sm={6} md={3} key={mission.missionId} className={classes.gridItemMissions}>

                          {index === 0 &&
                            <div className={classes.textMissions} >
                              <Info style={{ fontWeight: 'bold' }}>
                                Missions
                              </Info>
                            </div>
                          }

                          <Card className={classes.cardMissions} onClick={() => handleOpenDialog(mission)} >
                            <CardActionArea>
                              <CardBody plain>
                                <GridContainer>
                                  <GridItem xs={12} sm={4} md={4}>
                                    <CardIcon >
                                      {/* <div>{props.mission.missionId === mission.missionId ? "active" : "disable"}</div> */}
                                      <img className={classes.imageStyle} src={mission.missionB64} alt="..." />
                                    </CardIcon>
                                  </GridItem>

                                  <GridItem xs={12} sm={8} md={8} >
                                    <CardFooter className={classes.stats} >
                                      <Info style={{ fontWeight: 'bold' }}>{mission.missionDisplayName}</Info>
                                      <div className={classes.infoText}>
                                        <LinearProgressWithLabel value={mission.progress.progress} />
                                      </div>
                                    </CardFooter>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </CardActionArea>
                          </Card>
                        </GridItem>
                      ))}

                      {props.quest.isNexoolLinked
                        ? <GridItem xs={6} sm={6} md={3} className={classes.gridItemMissions}>
                          {/* <Card className={classes.cardMissions} onClick={() => props.history.push('/nexool')} > */}
                          <Card className={classes.cardMissions} onClick={onOpenURL} >
                            <CardActionArea>
                              <CardBody plain>
                                <GridContainer>
                                  <GridItem xs={12} sm={4} md={4}>
                                    <CardIcon >
                                      <img className={classes.imageStyle} src={ProductImage} alt="..." />
                                    </CardIcon>
                                  </GridItem>

                                  <GridItem xs={12} sm={8} md={8} >
                                    <CardFooter className={classes.stats} >
                                      <Info style={{ fontWeight: 'bold' }}>{"My Library"}</Info>
                                      <div className={classes.infoText} style={{ visibility: 'hidden' }}>
                                        <LinearProgressWithLabel value={0} />
                                      </div>
                                    </CardFooter>
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </CardActionArea>
                          </Card>
                        </GridItem>
                        : null
                      }
                      {props.apptype === 'Spectrum'
                        ? null
                        : <GridItem xs={12} sm={12} md={12} className={classes.gridItemMissions}>
                          <Card className={classes.bottomCard}>
                            <CardActionArea className={classes.bottomCardAction} onClick={openQuestDialog}>
                              <CardHeader
                                avatar={
                                  <Avatar aria-label="recipe" className={classes.avatar}>
                                    R
                                  </Avatar>
                                }
                                title={<Info style={{ fontWeight: 'bold', fontSize: 18, color: '#fff' }}>Other Recommended Courses</Info>}
                              />
                            </CardActionArea>
                          </Card>
                        </GridItem>
                      }
                    </React.Fragment>
                  }


                </GridContainer>
              </CardBody>
            </Paper>

          </GridItem>

          {/* <GridItem xs={12} sm={12} md={12}>

          </GridItem> */}

        </GridContainer>
      </div>
    </div>

  );
}

const mapStateToProps = state => {
  return {
    userAuthError: state.user.auth_error,
    userInfoError: state.user.error,
    infoLoading: state.user.loading,
    userInfo: state.user.userinfo,
    usertype: state.user.user_type,

    questLoading: state.quest.loading,
    userquest: state.quest.userquest,
    quest: state.quest.current_quest,

    loading: state.content.loading,
    questContent: state.content.contentlist,
    questProgress: state.content.current_quest_progress,

    leaderboardLoading: state.leaderboard.leader_board_data_loading,
    leaderboard: state.leaderboard.leader_board_data,

    mission: state.content.current_mission,
    apptype: state.appconfigs.apptype,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentQuest: (CurrentQuest) => dispatch(questActions.setCurrentQuest(CurrentQuest)),
    onInittContentList: (QuestId) => dispatch(questActions.initContentList(QuestId)),
    setCurrentMission: (mission) => dispatch(questActions.setCurrentMission(mission)),
    onInitLeaderBoard: (QuestId) => dispatch(questActions.initLeaderBoardData(QuestId))
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
