import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

// import material-ui/core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import Avatar from '@material-ui/core/Avatar';
// import Tooltip from '@material-ui/core/Tooltip';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// import material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import MessageIcon from '@material-ui/icons/Message';
import Person from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';


//import style
import styles from "assets/jss/components/customcomponents/webHeaderStyle.js";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);




    return (

        <AppBar className={classNames(classes.appBar)} >
            <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                <div className={classes.logoDiv}>
                <img src={props.logo} className={classes.imageStyle} alt="..." />
                </div>
                {/* <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                >
                    {props.logoText}
                </Typography> */}


                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                    classes={{ root: classes.bottomNavigation }}
                >
                    <BottomNavigationAction  onClick={()=> props.history.push('/home')}   label="Home" icon={<HomeIcon />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }} />
                    <BottomNavigationAction  onClick={()=> props.history.push('/home/dashboard')} label="Progress" icon={<BarChartIcon />} className={classes.buttonNavigationAction}  classes={{ selected: classes.buttonNavigationsSelected }}/>
                    <BottomNavigationAction onClick={()=> props.history.push('/home/connect')}label="Connect" icon={<MessageIcon />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }}/>
                    <BottomNavigationAction onClick={()=> props.history.push('/home/notification')}label="Notification" icon={<NotificationsIcon />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }}/>
                    <BottomNavigationAction onClick={()=> props.history.push('/home/profile')} label="Profile" icon={<Person />} className={classes.buttonNavigationAction} classes={{ selected: classes.buttonNavigationsSelected }}/>
               
                </BottomNavigation>
                
                <div></div>
                {/* onClick={()=> props.history.push('/dashboard')} */}
                {/* <Typography
                        component="h1"
                        variant="subtitle1"
                        color="inherit"
                        noWrap
                    >
                        Inventory
                        <IconButton
                            size="small"
                            color="inherit"
                            aria-label="Open drawer"
                            // onClick={this.handleDrawerOpen}
                            
                        >
                            <ExpandMore />
                        </IconButton>

                    </Typography> */}

                {/* <Tooltip title="Logout" aria-label="Logout">
                        <IconButton
                            aria-label="Logout"
                            color="inherit"
                            onClick={logout}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip> */}

                {/* <IconButton
                    aria-label=""
                    color="inherit"
                // onClick={() => this.logout()}
                >
                    <Avatar className={classes.avatarsmall} src="images/logo.png" />
                </IconButton> */}

            </Toolbar>
        </AppBar>


    );
}

AdminNavbarLinks.propTypes = {
    classes: PropTypes.object
};
export default withRouter(AdminNavbarLinks);