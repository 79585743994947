import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 400,
        flexGrow: 1,
    },
    mobileStepper: {
        flexGrow: 1,
        justifyContent: "center !important",
        background: 'transparent !important'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: '100%',
        display: 'block',
        overflow: 'hidden',
        width: '100%',
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    swipeableViewsContainer:{
       paddingTop:20
    },buttonDiv:{
        display: 'flex',
        justifyContent: 'space-between',
        // textAlign: 'center',
        alignItems: 'center',
        padding:'0 15px',
    },
    topicsTitle: {
        width:'85%',
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '2',
        },
    },


}));

export default useStyles;