import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { hot } from 'react-hot-loader'
import screenfull from 'screenfull'

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
// import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
// import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay10';
import ForwardIcon from '@material-ui/icons/Forward10';

//Redux
import { connect } from 'react-redux'

//import reducer 
import * as questActions from 'store/actions/index'

// import './reset.css'
import './style/defaults.css'
import './style/range.css'
import './style/ckin.css'

//config
import { serverURL, } from 'variables/ServerURL'
//axios
import { Token } from "util/commonfunctions";



import ReactPlayer from 'react-player'
import Duration from './Duration'

import { CURRENT_DATE_TIME } from "variables/general.js";
import { Flag } from '@material-ui/icons';

// const MULTIPLE_SOURCES = [
//     { src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4', type: 'video/mp4' },
//     { src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.ogv', type: 'video/ogv' },
//     { src: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.webm', type: 'video/webm' }
// ]

class VideoPalyer extends Component {
    constructor(props) {
        super(props);
        this.mousetrailRef = React.createRef();
        this.mouseConlRef = React.createRef();
    }
    state = {
        url: this.props.fileurl ? this.props.fileurl : null,
        pip: false,
        counter: 3,
        playing: true,
        controls: false,
        light: false,
        volume: 1,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        auth: true,
        tokan: Token(),
        Landscape: false,
        loading: true,
        markers: []
        // markers: [
        //     { time: 60, text: "test1", position: 0 },
        //     { time: 80, text: "test2", position: 0 },
        //     { time: 120, text: "test3", position: 0 },
        //     { time: 370, text: "test4", position: 0 },
        // ]

    }

    componentDidMount() {
        this.timerID = setInterval(this.tick, 1000);
        //this.mousetrailRef.current.addEventListener('mousemove', this.init)
        //this.mouseConlRef.current.addEventListener('mouseenter', this.coninit)
        //this.mouseConlRef.current.addEventListener('mouseleave', this.conleave)
    }


    // init = (e) => {
    //     console.log("333");
    //     clearInterval(this.timerID);
    //     this.setState({ counter: 3 });
    //     this.timerID = setInterval(this.tick, 1000);
    // }

    // coninit = (e) => {
    //     this.mousetrailRef.current.removeEventListener('mousemove', this.init)
    //     clearInterval(this.timerID);
    // }

    // conleave = (e) => {
    //     this.timerID = setInterval(this.tick, 1000);
    //     this.mousetrailRef.current.addEventListener('mousemove', this.init)
    // }

    tick = () => {
        if (this.state.counter == 0)
            clearInterval(this.timerID);
        else
            this.setState({ counter: this.state.counter - 1 });
    };

    componentWillUnmount() {
        clearInterval(this.timerID);
        const accessTime = this.state.duration * this.state.played
        // if (accessTime > 10 || accessTime === this.state.duration) {
        if (accessTime > 10) {
            const BODY_DATA = {
                "missionActivityId": "", // Don't set this
                "userId": this.props.userInfo.userId,
                "locationId": this.props.userInfo.locationId,
                "groupId": this.props.userInfo.groupId,
                "questId": this.props.quest.questId,
                "contentId": this.props.content.contentId,
                "contentTypeCode": this.props.content.contentTypeCode,
                "accessDate": "" + CURRENT_DATE_TIME(),
                "accessDuration": "" + accessTime,
                "missionActivitySummaryId": "" // Don't set this
            }
            this.props.onPostContentAccess(BODY_DATA)
        }
    }


    load = (url, auth) => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false,
            markers: [
                { time: 60, text: "test1", position: 0 },
                { time: 80, text: "test2", position: 0 },
                { time: 120, text: "test3", position: 0 },
                { time: 370, text: "test4", position: 0 },
            ],
            auth
        }, () => console.log(this.state.markers))
    }

    handleReady = () => {
        //console.log('onPause')
        this.setState({ loading: false })
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
        clearInterval(this.timerID);
        this.setState({ counter: 3 });
        this.timerID = setInterval(this.tick, 1000);
    }

    handleShowHide = async () => {
        clearInterval(this.timerID);
        this.timerID = setInterval(this.tick, 1000);
        if (this.state.counter === 0)
            this.setState({ counter: 3 });
        else
            this.setState({ counter: 0 });
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted, volume: this.state.muted ? 0.2 : 0 })
    }

    handleSetPlaybackRate = (value) => {
        this.setState({ playbackRate: parseFloat(value) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        this.setState({ pip: false })
    }

    handlePause = () => {
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        // this.props.updateCurrentContent(this.props.content.point,true)
        this.setState({ playing: this.state.loop })
        this.props.onEnded()
    }

    handleDuration = (duration) => {
        let markers = this.state.markers.map(value => {
            let new_value = { ...value }
            // Is position within range of the duration?
            if (new_value.time <= duration) {
                // Calculate position in percentage and add to the #seekbar.
                let left = (new_value.time / duration) * 100 + '%';
                // Create marker and give it the left value.
                new_value.position = left

            }
            return new_value;
        })
        this.setState({ markers, duration })
    }

    handleAESencryption = (xhr, url) => {
        if (url.includes("kid")) {
            url = `${serverURL}QuestContent/GetKey?ContentCode=${this.props.content.contentId}`
            xhr.open('GET', url, true);
        }
        if (url.includes("GetKey")) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.tokan)
        }

    }

    handleClickFullscreen = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(1)
            this.props.onChangeOrientation("landscape")
        } else {
            screenfull.request(findDOMNode(this.player))
        }
    }

    handleClickFullscreenExit = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(2)
            this.props.onChangeOrientation("portrait")
        }
    }


    renderLoadButton = (url, label, auth) => {
        return (
            <button onClick={() => this.load(url, auth)} style={{ margin: 5 }}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }

    handleForward = () => {
        if ((this.state.duration * this.state.played) > (this.state.duration - 10)) {
            this.setState((state, props) => ({ played: 0.999999 }))
        } else {
            this.setState((state, props) => ({
                played: state.played + (10 / this.state.duration)
            }))
            this.player.seekTo(parseFloat(this.state.played + (10 / this.state.duration)))
        }
    }

    handleReplay = () => {
        if ((this.state.duration * this.state.played) >= 10) {
            this.setState((state, props) => ({
                played: state.played - (10 / this.state.duration)
            }))
            this.player.seekTo(parseFloat(this.state.played - (10 / this.state.duration)))
        } else {
            this.setState((state, props) => ({
                played: 0
            }))
        }
    }

    render() {
        // const {loaded,auth, tokan }= this.state
        const { url, playing, controls, light, volume, muted, loop, played, duration, playbackRate, pip, loading, counter } = this.state
        // const SEPARATOR = ' · '

        return (
            <div className='app' ref={this.mousetrailRef}>


                <section className='section' >

                    <div className={this.props.orientation === "portrait" ? 'player-wrapper' : 'player-wrapper-landscape'}>

                        {/* <a href="javascript:;" className="getTime">Bookmark Here</a> */}
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={url}
                            config={{
                                file: {
                                    hlsOptions: {
                                        forceHLS: true,
                                        debug: false,
                                        xhrSetup: (xhr, url) => this.handleAESencryption(xhr, url)
                                    },
                                }
                            }}
                            playIcon={<button>Play</button>}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onReady={this.handleReady}
                            // onStart={() => console.log('onStart')}
                            onPlay={this.handlePlay}
                            onEnablePIP={this.handleEnablePIP}
                            onDisablePIP={this.handleDisablePIP}
                            onPause={this.handlePause}
                            // onBuffer={() => console.log('onBuffer')}
                            // onSeek={e => console.log('onSeek', e)}
                            onEnded={this.handleEnded}
                            onError={e => console.log('onError', e)}
                            onProgress={this.handleProgress}
                            onDuration={this.handleDuration}
                            playsinline={true}
                        />

                        <IconButton className={` ${counter !== 0 ? " default__button--big" : 'is-playing'}`} onClick={this.handlePlayPause}>
                            {playing ? <PauseRoundedIcon fontSize="large" /> : <PlayArrowRoundedIcon fontSize="large" />}
                        </IconButton>

                        <IconButton className={`${counter !== 0 ? "is-replay-control" : 'is-playing'}`} onClick={this.handleReplay}>
                            <ReplayIcon fontSize="large" />
                        </IconButton>

                        <IconButton className={`${counter !== 0 ? "is-forwar-control" : 'is-playing'}`} onClick={this.handleForward}>
                            <ForwardIcon fontSize="large" />
                        </IconButton>

                        {loading ? (
                            <div className="loader-div">
                                <div className="loader">Loading...</div>
                            </div>)
                            : null}

                        <marquee onClick={this.handleShowHide} onTouchEnd={this.handleTuchEnd} className="marquee-controls-down" direction="down" behavior="scroll" height="100%" scrollamount="1" scrolldelay="80">
                            <marquee behavior="scroll"><font >{this.props.userInfo.userName}</font></marquee>
                        </marquee>

                        {!loading
                            ? <div className={`${counter ? "speed-bar " : 'is-playing'}`}>
                                <IconButton
                                    style={{ color: playbackRate === 1 ? 'rgb(59, 89, 152)' : '#fff' }}
                                    onClick={() => this.handleSetPlaybackRate(1)}>
                                    <DirectionsWalkIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    style={{ color: playbackRate === 1.5 ? 'rgb(59, 89, 152)' : '#fff' }}
                                    onClick={() => this.handleSetPlaybackRate(1.5)}>
                                    <DirectionsRunIcon value={1.5} fontSize="small" />
                                </IconButton>
                                <IconButton
                                    style={{ color: playbackRate === 2 ? 'rgb(59, 89, 152)' : '#fff' }}
                                    onClick={() => this.handleSetPlaybackRate(2)}>
                                    <DirectionsBikeIcon value={2} fontSize="small" />
                                </IconButton>
                            </div>
                            : null
                        }


                        <div className="controls" ref={this.mouseConlRef}>


                            <div className="load-bar">
                                <input
                                    type='range' min={0} max={0.999999} step='any'
                                    value={played}
                                    className="slider"
                                    onMouseDown={this.handleSeekMouseDown}
                                    onChange={this.handleSeekChange}
                                    onMouseUp={this.handleSeekMouseUp}
                                    onTouchStart={this.handleSeekMouseDown}
                                    onTouchEnd={this.handleSeekMouseUp}
                                />
                                <progress className="load-progress" max={1} value={played} />

                                <div className="bookmark-bar">
                                    {this.state.markers.map((val, index) => {
                                        return (
                                            <div key={"vdi#" + index} style={{ left: val.position, position: 'absolute' }}>
                                                <button className="tooltip" data-title={val.text}></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className={`${counter !== 0 ? "button-controls" : 'is-playing-control'}`}>
                                <div className="play-button">
                                    <IconButton onClick={this.handlePlayPause}>{playing ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}</IconButton>
                                </div>

                                {/* <div className="volume-button">
                                    <button onClick={this.handleToggleMuted}>{muted ? <VolumeOffRoundedIcon /> : <VolumeUpRoundedIcon />}</button>
                                    <input type='range' className="slider-volume" min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />
                                </div> */}

                                <div className="duration-info">
                                    <div><Duration seconds={duration * played} /> /  <Duration seconds={duration} ></Duration> </div>
                                </div>

                                <div className="fullscreen-button">
                                    {/* {this.props.orientation === "portrait"
                                        ? <IconButton onClick={this.handleClickFullscreen}><FullscreenIcon /></IconButton>
                                        : <IconButton onClick={this.handleClickFullscreenExit}><FullscreenExit /></IconButton>
                                    } */}
                                    {
                                        typeof onRemenberMeClicked === 'undefined' ?
                                            this.props.orientation === "portrait"
                                                ? <IconButton onClick={this.handleClickFullscreen}><FullscreenIcon /></IconButton>
                                                : <IconButton onClick={this.handleClickFullscreenExit}><FullscreenExit /></IconButton>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div >
        )
    }
}



const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPostContentAccess: (accessData) => dispatch(questActions.postContentAccess(accessData)),//also update content,current_cuntent and quest_progress (updateCurrentContent)
    }

}

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(VideoPalyer));