import React, { useEffect } from "react";
//Redux
import { connect } from 'react-redux'

// @material-ui/core
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import LinearProgressWithLabel from 'components/CustomComponents/Progress/LinearProgressWithLabel'

// styles
import useStyles from "assets/jss/components/views/taskStyle";
import '../style.css'

//import reducer 
import * as questActions from 'store/actions/index'

const ChapterCards = (props) => {

    ///const contentList =getSteps()
    const classes = useStyles();
    // const [activeCard, setActiveCard] = React.useState(0);


    useEffect(() => {
        if (props.operation.missionId !== props.mission.missionId) {
            props.setCurrentOperation()
        }
    }, [props.mission])



    const handleCardSelect = (operation) => {
        props.setCurrentOperation(operation)
        props.onClick(operation);
    }

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12} >
                <div> </div>
                {props.questContent.add.operations.filter(current_value => props.mission.missionId === current_value.missionId).map((operation, index) => (
                    <Card key={operation.operationId} className={operation.operationId === props.operation.operationId ? classes.activeCard : classes.deactivateCard} style={{ cursor: 'pointer' }} onClick={() => handleCardSelect(operation)}>
                        <CardHeader
                            style={{ paddingBottom: 5 }}
                            action={
                                <IconButton aria-label="settings" >{/*onClick={() => handleCardSelect(operation)}*/}
                                    <ArrowForwardIcon />
                                </IconButton>
                            }
                            title={operation.sequenceNo + '.' + operation.operationDisplayName}
                            titleTypographyProps={{ variant: 'body1', className: classes.operationCardTitle }}
                        >
                        </CardHeader>
                        <CardContent >
                            <div style={{ display: 'flex' }}>
                                {operation.videoCount ?
                                    <div className={classes.contentText1}>
                                        <Typography variant="caption" component="h2" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            Video
                                    </Typography>
                                        <Typography variant="caption" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            {operation.progress.avCount}/{operation.videoCount}
                                        </Typography>
                                    </div>
                                    : null
                                }

                                {operation.testCount ?
                                    <div
                                        className={(Number(operation.videoCount)) > 0
                                            ? classes.contentText2
                                            : classes.contentText1}
                                    >
                                        <Typography variant="caption" component="h2" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            Test
                                        </Typography>
                                        <Typography variant="caption" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            {operation.progress.testCount}/{operation.testCount}
                                        </Typography>
                                    </div>
                                    : null
                                }

                                {operation.webContentCount ?
                                    <div
                                        className={(Number(operation.videoCount) + Number(operation.testCount)) > 0
                                            ? classes.contentText2
                                            : classes.contentText1}
                                    >
                                        <Typography variant="caption" component="h2" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            Web
                                        </Typography>
                                        <Typography variant="caption" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            {operation.progress.webContentCount}/{operation.webContentCount}
                                        </Typography>
                                    </div>
                                    : null
                                }

                                {operation.activityCount ?
                                    <div
                                        className={(Number(operation.videoCount) + Number(operation.testCount) + Number(operation.webContentCount)) > 0
                                            ? classes.contentText2
                                            : classes.contentText1}
                                    >
                                        <Typography variant="caption" component="h2" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            Activity
                                        </Typography>
                                        <Typography variant="caption" style={{ fontWeight: 'bold', color: '#000000' }}>
                                            {operation.progress.activityCount}/{operation.activityCount}
                                        </Typography>
                                    </div>
                                    : null
                                }


                            </div>
                        </CardContent>

                        <CardContent>
                            <LinearProgressWithLabel value={operation.progress.progress} />
                        </CardContent>
                    </Card>))}
            </GridItem>
        </GridContainer >
    )
}


const mapStateToProps = state => {
    return {
        questContent: state.content.contentlist,
        mission: state.content.current_mission,
        operation: state.content.current_operation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentOperation: (operation) => dispatch(questActions.setCurrentOperation(operation))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ChapterCards);




