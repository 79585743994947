import React, { useEffect, useState } from "react";
//redux
import { connect, useDispatch } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// axios
import axiosConfig from 'util/axios'

// @material-ui/core
import Backdrop from '@material-ui/core/Backdrop';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

// core components
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
import VideoPlayer from 'components/CustomComponents/VideoPlayer/VideoPlayer'
import Progress from "components/CustomComponents/Progress/Progress"
import Button from "components/CustomComponents/CustomButtons/Button"
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog"

//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/like';

//Style
import useStyles from 'assets/jss/components/views/videoViewerStyle'
//axios
import { Token } from "util/commonfunctions";

//variables
import { CURRENT_DATE_TIME } from "variables/general.js";

const SkeletonComp = () => (<React.Fragment >
    <Skeleton animation="wave" variant="circle" width={50} height={50} />
    <Skeleton animation="wave" variant="rect" width={50} height={40} />
</React.Fragment>)

const FlashAnimation = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        // rendererSettings: {
        //     preserveAspectRatio: "xMidYMid slice"
        // }
    };
    const classes = useStyles();
    useEffect(() => {
        let myInterval = null;
        if (props.open)
            myInterval = setInterval(() => {
                props.close()
            }, 1000)

        return () => {
            clearInterval(myInterval);
        };
    }, [props.open]);

    return (
        <div>
            <Backdrop open={props.open} className={classes.backdrop}>
                <Lottie options={defaultOptions}
                    height={'100%'}
                    width={'100%'} />
            </Backdrop>
        </div>
    )
}


const VideoViewer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [flashAnimation, setFlashAnimation] = useState(false)
    const [ScreenOrientation, setScreenOrientation] = useState('portrait')
    const [isIOS, setIOS] = useState(typeof onRemenberMeClicked)
    const [iosLoading, setIOSLoading] = useState(false)
    const [reatingPopup, setReatingPopup] = useState(false)

    /*---------------------ChangeScreenOrientation--------------------*/
    const onChangeScreenOrientation = (orientation) => {
        setScreenOrientation(orientation)
    }
    /*---------------------init Content Rating--------------------*/
    /*
    useEffect(() => {
        props.onInitContentRating(props.content.contentId)
    }, props.content.contentId)

    useEffect(() => {
        console.log(props);
        if (isIOS !== 'undefined') {
            console.log("IOs ");
            window.event = new Event('event');
            window.addEventListener('event', function(data) {
                // something is performed
                console.log("Called from WebView");
                WebViewEvent(data);
            }, false);
            const apptoken = Token();
            console.log('Previous like'+props.ratings.likeCount);
            console.log('Previous dislike'+props.ratings.disLikeCount);
            window.getHLSURL(props.content.fileUrl, props.ratings.likeCount, props.ratings.disLikeCount, props.ratings.myRating, props.content.contentId, apptoken);
            
            
        }
    }, props.content.contentId)
    */

    /*---------------------init Content Rating--------------------*/
    useEffect(() => {
        if (isIOS !== 'undefined') {
            initIOS()
        } else {
            props.onInitContentRating(props.content.contentId)
        }
    }, props.content.contentId)

    /*--------------- Code for IOS------------------- */
    const initIOS = () => {
        setIOSLoading(true)
        axiosConfig().get('/QuestContent/GetContentRating', {
            params: {
                ContentId: props.content.contentId,
            }
        }).then(response => {
            setIOSLoading(false)
            dispatch(questActions.setContentRating(response.data))
            window.event = new Event('event');
            window.addEventListener('event', function (data) {
                WebViewEvent(data);
            }, false);
            const apptoken = Token();
            window.getHLSURL(props.content.fileUrl, response.data.likeCount, response.data.disLikeCount, response.data.myRating, props.content.contentId, apptoken);

        }).catch(function (error) {
            setIOSLoading(false)
            console.log(error);
        });
    }

    const WebViewEvent = (data) => {
        if (data.detail) {
            switch (data.detail.type) {
                case 'rating': {
                    handleContentRating(data.detail.rating)
                    break;
                }
                case 'back': {
                    handleIOSBack(data.detail.duration)
                    break;
                }
                default:
                    return null;
            }
        }
    }
    const handleIOSBack = (accessTime) => {
        if (accessTime > 10) {
            const BODY_DATA = {
                "missionActivityId": "", // Don't set this
                "userId": props.userInfo.userId,
                "locationId": props.userInfo.locationId,
                "groupId": props.userInfo.groupId,
                "questId": props.quest.questId,
                "contentId": props.content.contentId,
                "contentTypeCode": props.content.contentTypeCode,
                "accessDate": "" + CURRENT_DATE_TIME(),
                "accessDuration": "" + accessTime,
                "missionActivitySummaryId": "" // Don't set this
            }
            props.onPostContentAccess(BODY_DATA)
        }
        props.history.goBack()
    }
    const handleContentRating = (rating) => {
        setReatingPopup(false)
        if (rating !== props.ratings.myRating) {
            const BODY_DATA = {
                "userId": props.userInfo.userId,
                "contentId": props.content.contentId,
                "rating": "" + rating
            }
            props.onPostContentRating(BODY_DATA);
            if (rating > 0)
                setFlashAnimation(true)
        }
    }



    /*
    if (isIOS !== 'undefined') {
        return (null);
    }
    */
    if (isIOS !== 'undefined') {
        return (
            <>
                { iosLoading
                    ? <Progress open={iosLoading} />
                    : null
                }
            </>
        );
    }
    // handel Video Ended
    const handelVideoEnded = () => {
        if (props.ratings.myRating === undefined || props.ratings.myRating === null || props.ratings.myRating === "")
            setReatingPopup(true)
    }

    return (
        <div>
            <AlertDialog open={reatingPopup} type="like" cancelable={() => { }}>
                <DialogActions >
                    <Button color={"rose"} startIcon={<ThumbUpIcon />} onClick={() => handleContentRating(5)}>Like</Button>
                    <Button color={"warning"} startIcon={<ThumbDownIcon />} onClick={() => handleContentRating(0)}>Dislike</Button>
                </DialogActions>
            </AlertDialog>

            {/*-----PageNavigation----- */}
            {ScreenOrientation === "portrait" ?
                <PageNavigation logoText={props.content.contentDisplayName} />
                : <CssBaseline />
            }


            {flashAnimation ? <FlashAnimation open={flashAnimation} close={setFlashAnimation} ></FlashAnimation> : null}

            {/*-----page-container----- */}
            <div className={`${ScreenOrientation === "portrait" ? 'video-container' : 'video-container-landscape'}  background-video-player`}>
                <div className={classes.centerContainer}>

                    <VideoPlayer
                        fileurl={props.content.fileUrl}
                        onChangeOrientation={onChangeScreenOrientation}
                        orientation={ScreenOrientation}
                        onEnded={handelVideoEnded}
                    />


                    {ScreenOrientation === "portrait"
                        ? <React.Fragment>
                            <div className={classes.footerDivStyle}>
                                <div className={classes.divStyle}>
                                    <div className={classes.divStyle} style={{ marginRight: 20 }}>
                                        {props.loading
                                            ? <div className={classes.divStyle} style={{ marginTop: 10 }}> <SkeletonComp /></div>
                                            : <React.Fragment>
                                                <IconButton aria-label="ThumbUpIcon" onClick={() => handleContentRating(5)}>
                                                    <ThumbUpIcon fontSize="large" style={{ color: props.ratings.myRating === 5 ? '#3B5998' : '#C0C0C0' }} />
                                                </IconButton>
                                                <Typography variant="subtitle2" >{props.ratings.likeCount}</Typography>
                                            </React.Fragment>
                                        }

                                    </div>
                                    <div className={classes.divStyle}>
                                        {props.loading
                                            ? <div className={classes.divStyle} style={{ marginTop: 10 }}>  <SkeletonComp /></div>
                                            : <React.Fragment>
                                                <IconButton aria-label="ThumbDownIcon" onClick={() => handleContentRating(0)}>
                                                    <ThumbDownIcon fontSize="large" style={{ color: props.ratings.myRating === 0 ? '#3B5998' : '#C0C0C0' }} />
                                                </IconButton>
                                                <Typography variant="subtitle2" >{props.ratings.disLikeCount}</Typography>
                                            </React.Fragment>
                                        }
                                    </div>

                                </div>
                                {/* <div><Typography variant="subtitle2" >10 Pints</Typography></div> */}
                            </div>
                        </React.Fragment>
                        : null}
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        content: state.content.current_content,
        ratings: state.content.content_ratings,
        loading: state.content.content_ratings_loading,
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitContentRating: (ContentId) => dispatch(questActions.initContentRating(ContentId)),
        onPostContentRating: (ratingData) => dispatch(questActions.postContentRating(ratingData)),
        onPostContentAccess: (accessData) => dispatch(questActions.postContentAccess(accessData))
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewer);