import React, { useState } from 'react';
//Redux
import { connect, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';

//Custom Components
import Button from 'components/CustomComponents/CustomButtons/Button'
// import TopicCards from 'components/Views/Tasks/Topics/TopicCards'
import AutoPlaySwipeableViews from 'components/Views/Tasks/Topics/TaskSwipeableViews'
import PointCardContainer from 'components/Views/Tasks/Topics/PointCardContainer'

// style
import useStyles from 'assets/jss/components/views/topicsContainerStyle'


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
// const AutoPlaySwipeableViews = (props) => {
//     const theme = useTheme();
//     return (
//         <SwipeableViews
//             axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//             index={props.activeStep}
//             containerStyle={{ width: '25%' }}
//             // onChangeIndex={handleStepChange}
//             enableMouseEvents
//         >
//             {props.topics.map((step, index) => (
//                 <div key={step.contentId+ index}>
//                     <TopicCards {...step} />
//                 </div>
//             ))}
//         </SwipeableViews>
//     )
// };


const CardConterol = (props) => {
    const theme = useTheme();
    return (
        <div>
            <Button justIcon color="white" size="sm" round onClick={props.onBack} disabled={props.activecard === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft />}
            </Button>

            <Button justIcon color="white" size="sm" round onClick={props.onNext} disabled={props.activecard === props.controlLength - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight />}
            </Button>
        </div>
    )
}


const TaskCard = (props) => {
    const classes = useStyles();
    const { task, topics } = props

    const currentContent = useSelector(state => state.content.current_content)

    const currentStep = topics.findIndex(element => element.contentId === currentContent.contentId);

    const [activeStep, setActiveStep] = useState(currentStep >= 0 ? currentStep : 0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        < React.Fragment >
            <div className={classes.buttonDiv}>
                <Typography variant="body1" className={classes.topicsTitle}>
                    {task.taskDisplayName}
                </Typography>
                <CardConterol onNext={handleNext} onBack={handleBack} activecard={activeStep} controlLength={topics.length} />
            </div>
            <AutoPlaySwipeableViews activeStep={activeStep} topics={topics} />
        </React.Fragment >

    )
}


function WebTopics(props) {
    const classes = useStyles();
    const questContentsList = props.content.add.questContents.filter((questContent) => (props.operation.operationId === questContent.operationId) && questContent["taskId"] === undefined)
    const taskList = props.content.add.tasks.filter((task) => props.operation.operationId === task.operationId)
    return (
        <div className={classes.root}>

            <PointCardContainer />

            {questContentsList.length ? <TaskCard task={""} topics={questContentsList} /> : null}

            {taskList.length
                ?
                taskList.map(current_task =>
                    <TaskCard
                        key={current_task.taskId}
                        task={current_task}
                        topics={props.content.add.questContents.filter((questContent) => current_task.taskId === questContent.taskId)} />
                ) : null
            }

        </div>
    );
}

const mapStateToProps = state => {
    return {
        content: state.content.contentlist,
        operation: state.content.current_operation
    }
}

export default connect(mapStateToProps, null)(WebTopics);

