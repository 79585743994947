// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  dialogContent: {
    textAlign: "center",
    padding: '8px 10px'
  },
  dialogTitle: {
    padding: '10px 10px 0px 10px'
  },
  dialogActions: {
    margin: '0px 10px 10px 10px',
    display: 'flex',
    justifyContent: "space-between",
    padding: '0px 8px 8px 8px'
  }

})


export default useStyles;