import React from 'react';
// nodejs library to set properties for components
import moment from 'moment'
import PropTypes from "prop-types";
//@material-ui/core
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { Avatar } from '@material-ui/core';

import TodayIcon from '@material-ui/icons/Today';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';

// core components
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Button from "components/CustomComponents/CustomButtons/Button.js";
// custom style
import useStyles from "./styles/messagesCardStyle";


const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        {['SK0011', 'DK0101', 'PL0111', 'LK1111'].map((v, i) =>
            <Card className={classes.cardStyle} key={v}>
                <CardContent>
                    <Skeleton animation="wave" variant="rect" />
                    <div style={{ height: 5 }}></div>
                    <Skeleton animation="wave" variant="rect" height={30} />
                </CardContent>

            </Card>
        )}
    </React.Fragment>
    )
}



const MessagesCard = (props) => {
    const classes = useStyles();
    const { loading, data, noDataMessage, onClickRow } = props;

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }


    const JoinButton = (props) => {
        const onClickJoin = () => {
            if (typeof window.Android === 'undefined' && typeof openNewUrl === 'undefined' && typeof window.openNewUrl === 'undefined') {
                window.open(props.data.joinLink, "_blank")
            } else {
                if (typeof window.Android !== 'undefined') {
                    if (typeof window.Android.openZoomUrl !== 'undefined') {
                        window.Android.openZoomUrl(props.data.appJoinLink)
                    }
                } else {
                    if(typeof window.openZoomUrl !== 'undefined')
                    {
                        window.openZoomUrl(props.data.appJoinLink)
                    }
                }
            }
        }
            if (props.type === '2') {
                return (<Button size='sm' color="facebook">Upcoming</Button>)
            }
            if (props.type === '1') {
                return <Button size='sm' onClick={onClickJoin} color="facebook">Join</Button>
            }
            return (<Button size='sm' color="warning">View recording</Button>)
        }

        return (
            <React.Fragment>
                {data.length ?
                    data.map((data, idex) => (
                        <Card key={idex + "CARD"} className={classes.cardStyle}>

                            <CardContent className={classes.cardContent}>
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <Typography variant="subtitle2" className={classes.senderName}>
                                        {data.CourseName}
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.subject}>
                                        {data.scheduleName}
                                    </Typography>
                                    <Typography variant="caption" className={classes.subject}>
                                        {data.Teacher}
                                    </Typography>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '16rem', paddingTop: 5 }}>
                                        <Typography variant="caption" className={classes.subject}>
                                            <TodayIcon className={classes.iconTime} />  {` ${moment(data.startTime).format('DD-MM-YYYY')}`}
                                        </Typography>
                                        <Typography variant="caption" className={classes.subject}>
                                            <AccessTimeIcon className={classes.iconTime} />   {`${moment(data.startTime).format('hh:mm:ss a')}`}
                                        </Typography>
                                        <Typography variant="caption" className={classes.subject}>
                                            <TimelapseIcon className={classes.iconTime} /> {`${data.duration}min`}
                                        </Typography>
                                    </div>


                                </div>
                            </CardContent>

                            <CardActions>
                                <JoinButton
                                    Duration={data.Duration}
                                    StartTime={data.StartTime}
                                    type={props.type}
                                    data={data}
                                />
                            </CardActions >
                        </Card>
                    ))
                    : <Card className={classes.cardStyle}>
                        <CardContent style={{ justifyContent: "center", display: 'flex' }}>
                            <Typography variant="body1" color="textSecondary">
                                {noDataMessage}
                            </Typography>

                        </CardContent>
                    </Card>
                }
            </React.Fragment>
        );
    }

    MessagesCard.propTypes = {
        loading: PropTypes.bool,
        data: PropTypes.array,
        noDataMessage: PropTypes.string,
        onClickRow: PropTypes.func
    };

    export default MessagesCard;