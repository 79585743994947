import React from 'react';
import { withRouter } from 'react-router-dom';
//import reducer 
import { connect } from 'react-redux'
import * as questActions from 'store/actions/index'
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
// @material-ui/core
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

//@material-ui/icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Common functions
import { clevertapEvents, facebookTracking } from "util/commonfunctions";

// styles
import useStyles from "assets/jss/components/views/taskStyle";

const GreenCheckIcon = withStyles({
    root: { color: green[400] }
})((props) => <CheckCircleIcon {...props} fontSize='small' />);

const ContentCard = (props) => {
    const classes = useStyles();
    const onClickContentCard = () => {

        //clevertap
        const eventData = JSON.stringify({
            'MisssionName': props.mission.missionName,
            'ContentType': props.content.contentTypeCode
        });
        clevertapEvents('ContentView', eventData);

        //facebook tracking
        const EventProperty = JSON.stringify({
            'fb_content_type':props.content.contentTypeCode,
            'fb_content_id':props.mission.missionName,
            'fb_currency':''
        })
        facebookTracking('fb_mobile_content_view',EventProperty)

        props.setCurrentContent(props.content)
        props.history.push(props.link)
    }

    // console.log(props);
    return (<Card className={classes.contentCardStyle} onClick={onClickContentCard}>
        <CardActionArea className={classes.cardActionArea}>{/*onClick={onClickContentCard}*/}
            <CardMedia
                className={props.content.imageUrl ? classes.cardMediaImg : classes.cardMedia}
                component="img"
                image={props.content.imageUrl ? props.content.imageUrl : props.imgPath}
                title={props.content.contentDisplayName}
            />
        </CardActionArea>
        <CardContent >
            <div style={{ display: 'flex', flexDirection: "column" }}>
                <>
                    <Typography variant="subtitle2" component="h2" className={classes.contentCardTitle}>
                        {props.content.contentDisplayName}
                    </Typography>
                </>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>

                    <div>
                        {props.content.time && <Typography variant="caption" style={{ paddingRight: 10, color: '#552E65' }}>
                            {props.content.time}
                        </Typography>
                        }

                        {props.content.points && <Typography variant="caption" style={{ color: '#552E65' }}>
                            {props.content.points} Points
                        </Typography>
                        }

                    </div>
                    {props.content.progress.accessCount > 0 && <GreenCheckIcon />}
                </div>
            </div>

        </CardContent>
    </Card >)
}

const mapSateToProps = state => {
    return {
        mission: state.content.current_mission,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentContent: (content) => dispatch(questActions.setCurrentContent(content))
    }
}

export default connect(mapSateToProps, mapDispatchToProps)(withRouter(ContentCard));



